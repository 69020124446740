import { Component } from '@angular/core';

@Component({
  templateUrl: './500.component.html'
})
export class P500Component {

  constructor() {
    const link = document.getElementById('bootstrap-css');
    const script = document.getElementById('bootstrap-js');
    if (link) link.parentNode.removeChild(link);
    if (script) script.parentNode.removeChild(script);
    document.getElementsByTagName('body')[0].style['background-color'] = null;
  }

}
