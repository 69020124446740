import {Injectable} from '@angular/core';
import {JwtHelper} from 'angular2-jwt';
@Injectable()
export class AuthService {

  constructor(public jwtHelper: JwtHelper) { }

  getToken(): string {
    return sessionStorage.getItem('token');
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }
}
