import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// Import Containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {RegisterComponent} from './views/register/register.component';
import {AuthGuard} from './services/auth/auth-guard.service';
import {RoleGuardService as RoleGuard} from './services/auth/role-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    // canActivate: [AuthGuard, RoleGuard],
    data: {
      title: 'Home',
      role: 'Admin'
    },
    children: [
      {
        path: 'admin',
        loadChildren: './views/admin/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'categories',
        loadChildren: './views/admin/category/category.module#CategoryModule'
      },
      {
        path: 'collection',
        loadChildren: './views/admin/collection/collection.module#CollectionModule'
      },
      {
        path: 'products',
        loadChildren: './views/admin/product/product.module#ProductModule'
      },
      {
        path: 'coupon',
        loadChildren: './views/admin/coupon/coupon.module#CouponModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
