import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import * as _ from 'lodash';

@Injectable()
export class CouponService {

  constructor(private httpClient: HttpClient) { }

  addCoupon(coupon): Observable<any> {
    return this.httpClient.post('/coupons', coupon, { observe: 'response' }).map((res: any) => {
      return res.body;
    });
  }

  createAuthorizationHeader(headers: Headers) {
    headers.append('Authorization', 'Basic ' +
      btoa('username:password'));
  }

  getAllCoupons(pageNo, recordPerPage, order?, sort?): Observable<any> {
    // const headers = new Headers();
    // this.createAuthorizationHeader(headers);
    const params = {
      page: pageNo,
      record: recordPerPage,
      order: order || 1,
      sort: sort || 'size',
    };
    return this.httpClient.get('/coupons', { params: params, observe: 'response' }).map(res => {
      return res.body;
    });
  }

  deleteCoupon(id) {
    return this.httpClient.delete(`/coupons/${id}`, {observe: 'response'}).map((res:any) => {
      return res.body;
    });
  }

  updateCoupon(coupon,id) {
    return this.httpClient.put(`/coupons/${id}`, coupon,{observe: 'response'}).map((res:any) => {
      return res.body;
    });
  }

  checkCouponCode(couponCode) {
    const headers = new HttpHeaders({
      coupon_name: couponCode
    });
    return this.httpClient.get(`/coupons/checkCouponCode`, {headers: headers}).map((res: any) => {
      return res;
    });
  }
}
