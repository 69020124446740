import {Injectable} from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import {AuthService} from './auth.service';
import * as decode from 'jwt-decode';
@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.role;
    const token = sessionStorage.getItem('token');

    if (token) {
      // logged in so return true
      // decode the token to get its payload
      const tokenPayload = decode(token);

      if (!this.auth.isAuthenticated() || tokenPayload.role !== expectedRole) {
        sessionStorage.clear();
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }
    return false;
  }
}
