import { Injectable } from '@angular/core';
import { Http, Request, RequestOptions, RequestOptionsArgs, Response, XHRBackend } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { JwtHelper } from 'angular2-jwt';

@Injectable()
export class HttpAuthService extends Http {

  jwtHelper: JwtHelper = new JwtHelper();

	constructor(backend: XHRBackend, options: RequestOptions, private routes: Router) {
		// add withCredentials to all requests
    super(backend, options);
    const token = sessionStorage.getItem('token'); // Cookies set through auth strategy
    if (token && token !== 'null') {
      const decodedUser = this.decodeUserFromToken(token);
      if (this.jwtHelper.isTokenExpired(token)) {
        sessionStorage.clear();
        this.routes.navigateByUrl('/login');
      }
    }
	}

  decodeUserFromToken(token) {
    try {
      return this.jwtHelper.decodeToken(token);
    } catch (e) {
      sessionStorage.clear();
      this.routes.navigateByUrl('/login');
    }
  }

	get(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
		return super.request(url, options);
	}

}
