import {Component, OnInit} from '@angular/core';
import {User} from '../../model/user';
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  user: User;
  confirmPassword: string;
  emailPattern = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;

  constructor(public loginService: LoginService) {

    const link = document.getElementById('bootstrap-css');
    const script = document.getElementById('bootstrap-js');
    if (link) link.parentNode.removeChild(link);
    if (script) script.parentNode.removeChild(script);
    document.getElementsByTagName('body')[0].style['background-color'] = null;
  }

  ngOnInit() {
    this.user = new User();
  }

  register(confirm, registerForm) {
    if (registerForm.valid && confirm.valid && this.user.password === confirm.value) {
      this.loginService.register(this.user).subscribe(res => {
        debugger;
      });
    }
  }

}
