export const adminNav = [
  {
    name: 'Categories',
    url: '/categories',
    icon: 'icon-book-open'
  },
  {
    name: 'Products',
    url: '/products',
    icon: 'icon-basket'
  },
  {
    name: 'Collection',
    url: '/collection',
    icon: 'icon-film'
  },
  {
    name: 'Coupon',
    url: '/coupon',
    icon: 'fa fa-ticket'
  }
];
