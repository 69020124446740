import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import * as _ from 'lodash';
import {User} from "../model/user";

@Injectable()
export class LoginService {

  constructor(private httpClient: HttpClient) { }

  createAuthorizationHeader(headers: Headers) {
    headers.append('Authorization', 'Basic ' +
      btoa('username:password'));
  }

  login(user: User): Observable<any> {
    return this.httpClient.post('/login', user, { observe: 'response' }).map(res => {
      return res.body;
    });
  }

  register(user: User): Observable<any> {
    return this.httpClient.post('/signup', user,{ observe: 'response' }).map((res: any) => {
      return res.body;
    });
  }
}

/*
export class LoginService {

  jwtHelper: JwtHelper = new JwtHelper();

  constructor(private httpClient: HttpClient, private routes: Router) {
    const token = sessionStorage.getItem('token'); // Cookies set through auth strategy
    if (token && token !== 'null') {
      const decodedUser = this.decodeUserFromToken(token);
      if (this.jwtHelper.isTokenExpired(token)) {
        sessionStorage.clear();
        this.routes.navigateByUrl('/login');
      }
    }
  }

  decodeUserFromToken(token) {
    try {
      return this.jwtHelper.decodeToken(token);
    } catch (e) {
      sessionStorage.clear();
      this.routes.navigateByUrl('/login');
    }
  }

  /!*private setHeaders(): Headers {
      const token = JSON.parse(sessionStorage.User).token;
      const headersConfig = {
          'Accept': 'application/json',
          'authorization': token
      };
      return new HttpHeaders(headersConfig);
  }*!/

  dashboard() {
    // return this.http.get('http://localhost:3000/protected', {headers: this.setHeaders(), observe: 'response'});
  }

  login(admin: User): Observable<any> {
    debugger
    return this.httpClient.post<any>(`/login`,
      admin, {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        observe: 'response'
      }).map((response: any) => {
      sessionStorage.setItem('token', response.body.token);
      return response.body;
    });
  }

  register(admin: User) {
    debugger
    return this.httpClient.post('/client', admin, { observe: 'response' }).map((res: any) => {
      debugger
      return res.body;
    });
  }
}
*/
