import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import * as _ from 'lodash';

@Injectable()
export class CategoriesService {

  constructor(private httpClient: HttpClient) { }

  createAuthorizationHeader(headers: Headers) {
    headers.append('Authorization', 'Basic ' +
      btoa('username:password'));
  }


  getAllCategories(pageNo, recordPerPage, order?): Observable<any> {
    const headers = new Headers();
    this.createAuthorizationHeader(headers);
    const params = {
      page: pageNo,
      record: recordPerPage,
      order: order || 1
    };
    return this.httpClient.get('/categories', { params: params, observe: 'response' }).map(res => {
      _.each(res.body, (category) => {
        if (category.image) {
          category.image = category.image.replace('server', '');
          category.url = `${category.name.split(/ /g).join('-').toLowerCase()}`;
        }
      });
      return res.body;
    });
  }

  addCategory(category): Observable<any> {
    return this.httpClient.post('/categories', category, { observe: 'response' }).map((res: any) => {
      res.body.image = res.body.image.replace('server', '');
      return res.body;
    });
  }

  deleteCategory(id) {
    return this.httpClient.delete(`/categories/${id}`, { observe: 'response' }).map((res: any) => {
      return res.body;
    });
  }

  updateCategory(category, id) {
    return this.httpClient.put(`/categories/${id}`, category, { observe: 'response' }).map((res: any) => {
      res.body.image = res.body.image.replace('server', '');
      return res.body;
    });
  }
}
