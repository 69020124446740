import {Component, OnInit} from '@angular/core';
import {User} from '../../model/user';
import {LoginService} from '../../services/login.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: User;
  emailPattern = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;

  constructor(
    public toastr: ToastrService,
    public loginService: LoginService,
    public route: Router) {

    const link = document.getElementById('bootstrap-css');
    const script = document.getElementById('bootstrap-js');
    if (link) link.parentNode.removeChild(link);
    if (script) script.parentNode.removeChild(script);
    document.getElementsByTagName('body')[0].style['background-color'] = null;

  }

  ngOnInit() {
    this.user = new User();
  }

  login(loginForm) {
    if (loginForm.valid) {
      this.loginService.login(loginForm.value).subscribe(response => {
        if (response.token) {
          this.toastr.success('You Logged In', 'Success!');
          this.route.navigate(['/admin']);
        }
      });
    }
  }

  redirect() {
    this.route.navigate(['/register']);
  }
}
